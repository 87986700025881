import React, { Component } from 'react';
// import sstyles from './Dashboard.module.scss';
import { RouteComponentProps } from 'react-router-dom';
import { Box, StyledComponentProps } from '@material-ui/core';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';
import LineChart from '../charts/LineChart/LineChart';
import { DateValueDataType, LineChartModel, LineChartXDataType } from '../../models/charts/LineChartModel';
import Axios from 'axios';
import { ApiResponseLogData } from '../../models/api_responses';

const styles = {
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
};

interface Props extends RouteComponentProps, StyledComponentProps {
  classes: Partial<ClassNameMap<string>>;
}

interface State {
  chartModel: LineChartModel;
}

const Dashboard = withStyles(styles)(
  class extends Component<Props, State> {

    private intervals: number[] = [];

    constructor(props: Props) {
      super(props);
      this.state = {
        chartModel: {
          title: "Temperature in last 10 minutes",
          valueLabel: "Temperature",
          xAxisLabel: "Time",
          yAxisLabel: "Temperature (in celcius)",
          data: [],
          dataCounter: 0,
          xTypeFormat: LineChartXDataType.LINE_CHART_TYPE_MINUTE
        }
      }
    }

    private isRunFunctionSetup: boolean = false;
    private runFunction = () => {
      let  deviceId : string = "25E470";
      Axios.get("http://localhost:3050/device/" + deviceId + "/data", {
        headers: {
          "content-type": "application/json"
        }
      }).then(value => {
        if (value.data.response === "FAILED") {
          console.log("FAILED RES: ", value.data)
          return;
        }
        let data = value.data.data;
        let revData = data.reverse();
        let formattedData: DateValueDataType[] = []
        revData.forEach((val: ApiResponseLogData) => formattedData.push({ timestamp: new Date(val.timestamp), value: val.value }));
        console.log("SUCCESS", data);
        if (revData.length > 1) {
          this.setState((prevState, props) => {
            return {
              chartModel: {
                title: prevState.chartModel.title,
                valueLabel: prevState.chartModel.valueLabel,
                xAxisLabel: prevState.chartModel.xAxisLabel,
                yAxisLabel: prevState.chartModel.yAxisLabel,
                data: formattedData,
                dataCounter: prevState.chartModel.dataCounter + 1,
                xTypeFormat: prevState.chartModel.xTypeFormat
              }
            };
          });
        }
      }).catch(reason => {
        console.log("API ERROR", reason);
      })
      if (!this.isRunFunctionSetup) {
        this.isRunFunctionSetup = true;
        this.intervals.push(window.setInterval(this.runFunction, 20000));
      }
    }

    componentDidMount() {
      //Initiate API Call here.
      console.log("COMP DASHBOARD DID MOUNT");
      this.runFunction();
    }

    componentWillUnmount() {
      console.log("Clearing intervals for Charts.")
      this.intervals.forEach(i => window.clearInterval(i));
    }

    render() {
      return (
        <Box>
          <Box>
            <LineChart chartModel={this.state.chartModel} />
          </Box>
        </Box>
      );
    }
  }
);
export default Dashboard;
