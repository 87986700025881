import React from 'react';
import {
  Box, TextField, Button, withStyles, StyledComponentProps, Dialog, DialogTitle,
  DialogActions, DialogContent, Grid, FormControl, InputLabel, MenuItem, Select,
  CircularProgress, Theme, createStyles
} from '@material-ui/core';
import { MbRegisterModel } from '../../../models/modbus/ModbusModels';
import { ModbusDataTypeJson, ModbusRegTypeJson } from '../../../models/modbus/enumerations';
import { green } from '@material-ui/core/colors';


const styles : any = {
  dialogContainer: {
  },
  dialogContent: {

  },
  regName: {
    width: '100%'
  },
  slaveAddress: {
    width: '100%'
  },
  regAddrInput: {
    width: '100%'
  },
  regTypeInput: {
    width: '100%'
  },
  dataTypeInput: {
    width: '100%'
  },
  dataEndiannessInput: {
    width: '100%'
  },
  buttonWrapperDiv: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
};

const styleWithTheme = (theme: Theme) => createStyles(styles)

interface Props extends StyledComponentProps {
  classes: Partial<Record<string, string>>; // To avoid possible classes? undefined
  isOpen: boolean;
  isModeNew: boolean;
  editModeIndex?: number;
  editableModel: MbRegisterModel;
  onClose: () => void;
  onSubmit: (model: MbRegisterModel, isNew: boolean, index?: number) => void;
}
interface State {
  model: MbRegisterModel;
  submitButtonLoading: boolean;
}

const SensorDialog = withStyles(styleWithTheme, { withTheme: true })(
  class extends React.Component<Props, State> {

    constructor(props: Props) {
      super(props)
      this.state = {
        model: props.editableModel,
        submitButtonLoading: false
      }
      this.onSubmit = this.onSubmit.bind(this);
      this.changeRegName = this.changeRegName.bind(this)
      this.changeSlaveAddress = this.changeSlaveAddress.bind(this)
      this.changeRegAddress = this.changeRegAddress.bind(this)
      this.changeRegType = this.changeRegType.bind(this);
      this.changeDataType = this.changeDataType.bind(this);
    }

    onSubmit(e: any) {
      this.setState({
        submitButtonLoading: true
      })
      console.log("DINDLDSD", this.props.editModeIndex)
      this.props.onSubmit(this.state.model, this.props.isModeNew, this.props.editModeIndex)
    }

    changeRegName(event: any) {
      event.persist();
      this.setState((prevState: State) => {
        return {
          model: {
            ...prevState.model,
            name: event.target.value
          }
        }
      })
    }
    changeSlaveAddress(event: any) {
      event.persist();
      this.setState((prevState: State) => {
        return {
          model: {
            ...prevState.model,
            plc_id: Number(event.target.value)
          }
        }
      })
    }
    changeRegAddress(event: any) {
      event.persist();
      this.setState((prevState: State) => {
        return {
          model: {
            ...prevState.model,
            reg_addr: Number(event.target.value)
          }
        }
      })
    }
    changeRegType(event: any) {
      event.persist();
      this.setState((prevState: State) => {
        return {
          model: {
            ...prevState.model,
            reg_type: event.target.value
          }
        }
      })
    }
    changeDataType(event: any) {
      event.persist();
      this.setState((prevState: State) => {
        return {
          model: {
            ...prevState.model,
            data_type: event.target.value
          }
        }
      })
    }
    changeEndianness(event: any) {
      event.persist();
      this.setState((prevState: State) => {
        return {
          model: {
            ...prevState.model,
            data_endianness: event.target.value
          }
        }
      })
    }


    componentDidUpdate(prevProps: Readonly<Props>, prevState: State, snapshot?: any) {
      if (
        this.props.isOpen && prevProps.isOpen !== this.props.isOpen
      ) {
        // console.log("Model mismatch")
        this.setState({
          model: this.props.editableModel,
          submitButtonLoading: false
        })
        // } else {
        //   console.log("Same model")
      }
    }

    render() {
      return (
        <Dialog className={this.props.classes.dialogContainer} fullWidth maxWidth={"md"} onClose={this.props.onClose}
          aria-labelledby="dialog-title-item" open={this.props.isOpen}
          disableBackdropClick>
          <DialogTitle id="dialog-title-item">
            <Box>
              SENSOR PROPERTIES
            </Box>
          </DialogTitle>
          <DialogContent style={{ paddingBottom: "20px" }} dividers>
            <form noValidate autoComplete="off" >
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField id={"reg_name"} className={this.props.classes.regName}
                    label="Name" variant="outlined" defaultValue={this.state.model.name} type="text"
                    inputProps={{ style: { textAlign: 'left' } }} onBlur={this.changeRegName} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField id={"slave_addr"} className={this.props.classes.slaveAddress}
                    label="Slave Address" variant="outlined" defaultValue={this.state.model.plc_id} type="number"
                    inputProps={{ style: { textAlign: 'left' } }} onBlur={this.changeSlaveAddress} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl variant="outlined" className={this.props.classes.regTypeInput}>
                    <InputLabel id="reg_type_label">Register Type</InputLabel>
                    <Select
                      labelId="reg_type_label"
                      id="reg_type_select"
                      value={this.state.model.reg_type}
                      onChange={this.changeRegType}
                      label="Register Type"
                    >
                      {
                        Object.keys(ModbusRegTypeJson).map((k: string, regIndex: number) => (
                          <MenuItem value={k} key={k} >{ModbusRegTypeJson[k].name}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField id={"addr_start"} className={this.props.classes.regAddrInput}
                    label="Start Address" variant="outlined" defaultValue={this.state.model.reg_addr} type="number"
                    inputProps={{ style: { textAlign: 'left' } }} onBlur={this.changeRegAddress} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl variant="outlined" className={this.props.classes.dataTypeInput} >
                    <InputLabel id="data_type_label">Data Type</InputLabel>
                    <Select
                      labelId="data_type_label"
                      id="data_type_select"
                      value={this.state.model.data_type}
                      onChange={this.changeDataType}
                      label="Data Type"
                    >
                      {
                        Object.keys(ModbusDataTypeJson).map((k: string) => (
                          <MenuItem value={k} key={k} >{ModbusDataTypeJson[k].name}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl variant="outlined" className={this.props.classes.dataEndiannessInput}>
                    <InputLabel id="endian_label">Endianness</InputLabel>
                    <Select
                      labelId="endian_label"
                      id="endian_select"
                      value={this.state.model.data_endianness === "BIG" ? "BIG" : 'LITTLE'}
                      onChange={this.changeEndianness}
                      label="Endianness"
                      disabled={true}
                    >
                      <MenuItem value={"LITTLE"} >LITTLE</MenuItem>
                      <MenuItem value={"BIG"} >BIG</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <div>
              <Button onClick={this.props.onClose} variant={"contained"}>
                Cancel
             </Button>
            </div>
            <div className={this.props.classes.buttonWrapperDiv}>
              <Button onClick={this.onSubmit} color="primary" variant={"contained"}
                disabled={this.state.submitButtonLoading} >
                Submit
              </Button>
              {this.state.submitButtonLoading && <CircularProgress size={24} className={this.props.classes.buttonProgress} />}
            </div>
          </DialogActions>
        </Dialog>
      )
    }
  }
);
export default SensorDialog;
