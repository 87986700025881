import React from 'react';
import styles from './App.module.scss';
import { AppBar, Toolbar, Typography, Button, makeStyles, createStyles, Theme } from '@material-ui/core';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// import DummyComp from './components/Dummy/Dummy';
// import CompanyComp from './components/CompanyComp/CompanyComp';
import Dashboard from './components/Dashboard/Dashboard';
import SettingRegisters from './components/settings/SettingRegisters/SettingRegisters';
// import SettingSlaves from './components/settings/SettingSlaves/SettingSlaves';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      textAlign: "left",
    },
  }),
);

function App() {
  const classes = useStyles();

  return (
    <div className={styles.App}>
      <Router>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              ModTerm
            </Typography>
            <Button color="inherit" component={Link} to={"/"}>Home</Button>
            <Button color="inherit" component={Link} to={"/dashboard"}>Dashbaord</Button>
            <Button color="inherit" component={Link} to={"/registers"}>Sensors</Button>
            {/* <Button color="inherit" component={Link} to={"/slaves"}>Slaves</Button> */}
            {/* <Button color="inherit" component={Link} to={"/company"}>Company</Button>
            <Button color="inherit" component={Link} to={"/dummy"}>Dummy</Button> */}
          </Toolbar>
        </AppBar>
        {/* <Route path="/dummy" exact component={DummyComp} />
        <Route path="/company" exact component={CompanyComp} /> */}
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/registers" exact component={SettingRegisters} />
        {/* <Route path="/slaves" exact component={SettingSlaves} /> */}
      </Router>
    </div>
  );
}

export default App;
