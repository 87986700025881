export const ModbusRegTypeJson: {
    [key: string]: {
        [key: string]: string
    };
} = {
    COIL: {
        name: "Coil",
        regName: "REG_COIL",
        addrStart: "0xxxx"
    },
    DISCRETE_INPUT: {
        name: "Discrete Input",
        regName: "REG_DISCRETE_INPUT",
        addrStart: "1xxxx"
    },
    INPUT_REGISTER: {
        name: "Input Register",
        regName: "REG_INPUT_REGISTER",
        addrStart: "3xxxx"
    },
    HOLDING_REGISTER: {
        name: "Holding Register",
        regName: "REG_HOLDING_REGISTER",
        addrStart: "4xxxx"
    }
}

export const ModbusDataTypeJson: {
    [key: string]: {
        [key: string]: string | number
    };
} = {
    BIT: {
        name: "Bit",
        modbusName: "MODBUS_DATATYPE_BIT",
        bitSize: 1
    },
    BYTE: {
        name: "Byte",
        modbusName: "MODBUS_DATATYPE_BYTE",
        bitSize: 8
    },
    WORD: {
        name: "Word",
        modbusName: "MODBUS_DATATYPE_WORD",
        bitSize: 16
    },
    DWORD: {
        name: "DWord",
        modbusName: "MODBUS_DATATYPE_DWORD",
        bitSize: 32
    },
    QWORD: {
        name: "QWord",
        modbusName: "MODBUS_DATATYPE_QWORD",
        bitSize: 64
    },
    LWORD: {
        name: "LWord",
        modbusName: "MODBUS_DATATYPE_LWORD",
        bitSize: 64
    },
    CHAR: {
        name: "Char",
        modbusName: "MODBUS_DATATYPE_CHAR",
        bitSize: 8
    },
    WCHAR: {
        name: "WChar",
        modbusName: "MODBUS_DATATYPE_WCHAR",
        bitSize: 16
    },
    STRING: {
        name: "String",
        modbusName: "MODBUS_DATATYPE_STRING",
        bitSize: 8
    },
    WSTRING: {
        name: "WString",
        modbusName: "MODBUS_DATATYPE_WSTRING",
        bitSize: 16
    },
    INT8: {
        name: "INT8",
        modbusName: "MODBUS_DATATYPE_INT8",
        bitSize: 8
    },
    INT16: {
        name: "INT16",
        modbusName: "MODBUS_DATATYPE_INT16",
        bitSize: 16
    },
    INT32: {
        name: "INT32",
        modbusName: "MODBUS_DATATYPE_INT32",
        bitSize: 32
    },
    INT64: {
        name: "INT64",
        modbusName: "MODBUS_DATATYPE_INT64",
        bitSize: 64
    },
    UINT8: {
        name: "UINT8",
        modbusName: "MODBUS_DATATYPE_UINT8",
        bitSize: 8
    },
    UINT16: {
        name: "UINT16",
        modbusName: "MODBUS_DATATYPE_UINT16",
        bitSize: 16
    },
    UINT32: {
        name: "UINT32",
        modbusName: "MODBUS_DATATYPE_UINT32",
        bitSize: 32
    },
    UINT64: {
        name: "UINT64",
        modbusName: "MODBUS_DATATYPE_UINT64",
        bitSize: 64
    },
    REAL: {
        name: "REAL",
        modbusName: "MODBUS_DATATYPE_REAL",
        bitSize: 32
    },
    LREAL: {
        name: "LREAL",
        modbusName: "MODBUS_DATATYPE_LREAL",
        bitSize: 64
    }
}
