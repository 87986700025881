import { Box } from '@material-ui/core';
import * as d3 from 'd3';
import React, { Component, createRef, RefObject } from 'react';
import { DateValueDataType, LineChartModel, LineChartXDataType } from '../../../models/charts/LineChartModel';
import styles from './LineChart.module.scss';


interface Props {
  chartModel?: LineChartModel;
  // data?: DateValueDataType[];
  // dataUpdateCounter?: number;
}

interface State {
}

class LineChart extends Component<Props, State> {

  private ref: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
  private svgRef: RefObject<SVGSVGElement> = createRef<SVGSVGElement>();

  private c: {
    margin: { [key: string]: number };
    width: number;
    height: number;
    svgTranformedg?: d3.Selection<SVGGElement, DateValueDataType[], any, any>;
    svgOverlayRect?: d3.Selection<SVGRectElement, DateValueDataType[], any, any>;
    svgxAxis?: d3.Selection<SVGGElement, any, any, any>;
    svgyAxis?: d3.Selection<SVGGElement, any, any, any>;
    svgyaxisText?: d3.Selection<SVGTextElement, any, any, any>;
    svgPath?: d3.Selection<SVGPathElement, DateValueDataType[], any, any>;
    svgTextRectGroup?: d3.Selection<SVGGElement, any, any, any>;

    divTooltip?: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;

    focus?: d3.Selection<SVGGElement, any, any, any>;
    xScale: d3.ScaleTime<number, number>;
    yScale: d3.ScaleLinear<number, number>;
    xAxis: d3.Axis<Date>;
    yAxis: d3.Axis<number>;
  };

  constructor(props: Props) {
    super(props);
    this.state = {
    }
    let xscale = d3.scaleTime();
    let yscale = d3.scaleLinear()
    this.c = {
      margin: {},
      width: 0,
      height: 0,
      xScale: xscale,
      yScale: yscale,
      xAxis: d3.axisBottom<Date>(xscale),
      yAxis: d3.axisLeft<number>(yscale)
    }
  }

  private getTimeFormatter(xDataType: LineChartXDataType): (date: Date) => string {
    switch (xDataType) {
      case LineChartXDataType.LINE_CHART_TYPE_MINUTE:
        return d3.timeFormat("%M:%S");
      case LineChartXDataType.LINE_CHART_TYPE_HOURS:
        return d3.timeFormat("%H:%M:%S");
      case LineChartXDataType.LINE_CHART_TYPE_DAYS:
        return d3.timeFormat("%d/%m/%y %H:%M:%S");
      default:
        throw Error("Time format not supported yet")
    }
  }

  private onNewData = () => {
    let data: DateValueDataType[] = this.props.chartModel!.data;
    if (data.length < 2) {
      return; //Better this condition should emtpy the plot again.
    }
    this.c.xScale.domain([data[0].timestamp, (data[data.length - 1].timestamp)]); //Start and end point on axis
    let minAndMaxOfYdata: [number, number] | [undefined, undefined] = d3.extent(data, function (d) { return d.value; });
    this.c.yScale.domain([minAndMaxOfYdata[0]! - 1, minAndMaxOfYdata[1]! + 1]);

    this.c.svgxAxis!.call(this.c.xAxis);
    this.c.svgyAxis!.call(this.c.yAxis);

    this.c.svgyaxisText!.text(this.props.chartModel!.yAxisLabel);

    let line = d3.line<DateValueDataType>()
      .x(d => this.c.xScale(d.timestamp) ?? 0)
      .y(d => this.c.yScale(d.value) ?? 0);

    this.c.svgPath!.datum(data).attr("d", line);

    // ToolTip
    this.c.svgOverlayRect!
      .on("mouseover", () => { this.c.focus!.style("display", null); this.c.divTooltip!.style("display", null); })
      .on("mouseout", () => { this.c.focus!.style("display", "none"); this.c.divTooltip!.style("display", "none"); })
      .on("mousemove", (datum: unknown, index: number, groups: SVGRectElement[] | ArrayLike<SVGRectElement>) => {
        // console.log("ON MOUSE MOVE", d, i, groups);
        // this.mousemove(this.c.xScale, this.c.yScale, this.c.focus, groups[index]);
        if (data.length < 2) {
          // console.log("Ignoring mousemove for tip as there are not enough elements.");
          return;
        }
        let rect: SVGRectElement = groups[index];
        let bisectDate: (array: ArrayLike<DateValueDataType>, x: Date, lo?: number, hi?: number) => number
          = d3.bisector((d: DateValueDataType) => {
            // console.log("TIMESTAMP : ", d);
            return d.timestamp.getTime();
          }).left;
        let x0: Date = this.c.xScale.invert(d3.mouse(rect)[0]);
        let i: number = bisectDate(data, x0, 1); // i ranges from 1 to data.length-1
        let d0: DateValueDataType = data[i - 1];
        let d1: DateValueDataType = data[i];
        if (!d0 || !d1) {
          console.log("IN MOUSEMOVE: ", d0, d1, i, x0);
          return;
        }
        let d: DateValueDataType = (x0.getTime() - d0.timestamp.getTime()) > (d1.timestamp.getTime() - x0.getTime()) ? d1 : d0;
        // let dPercent: number = data.indexOf(d) * 100 / (data.length - 1);
        // console.log("D PERCENT: ", dPercent);
        this.c.focus!.attr("transform", "translate(" + this.c.xScale(d.timestamp) + "," + this.c.yScale(d.value) + ")");
        //64 should be +20px more than 50% of the div width
        this!.c!.divTooltip!.attr("style", "left:" + ((this.c!.xScale(d!.timestamp!)!) + 80) + "px; top:" + (this!.c!.yScale(d!.value!)!) + "px;");
        this.c.divTooltip!.select("." + styles.divTooltipDate).text(this.getTimeFormatter(this.props.chartModel!.xTypeFormat)(d.timestamp));
        this.c.divTooltip!.select("." + styles.divTooltipYvalue).text(d3.format(",")(d.value));
      });
  }

  componentDidMount() {
    this.c.margin = { top: 30, right: 50, bottom: 30, left: 70 };
    this.c.width = 960 - this.c.margin.left - this.c.margin.right;
    this.c.height = 500 - this.c.margin.top - this.c.margin.bottom;

    this.c.svgTranformedg = d3.select<SVGSVGElement, DateValueDataType[]>(this.svgRef.current!)
      .attr("width", this.c.width + this.c.margin.left + this.c.margin.right)
      .attr("height", this.c.height + this.c.margin.top + this.c.margin.bottom)
      .append("g").attr("transform", "translate(" + this.c.margin.left + "," + this.c.margin.top + ")");

    this.c.xScale.range([0, this.c.width]);
    this.c.yScale.range([this.c.height, 0]);

    // xAxis.ticks(data.length);
    this.c.xAxis.tickFormat(this.getTimeFormatter(this.props.chartModel!.xTypeFormat));
    this.c.svgxAxis = this.c.svgTranformedg!.append("g")
      .attr("class", [styles.xaxis, styles.axis].join(' '))
      .attr("transform", "translate(0," + this.c.height + ")")
      .call(this.c.xAxis);

    // yAxis.ticks(data.length);
    this.c.yAxis.tickFormat(d3.format("~s"));
    this.c.svgyAxis = this.c.svgTranformedg!.append("g")
      .attr("class", [styles.yaxis, styles.axis].join(' '))
      .call(this.c.yAxis);
    this.c.svgyaxisText = this.c.svgyAxis
      .append("text")
      .attr("class", styles.ytext)
      .attr("transform", "rotate(-90) translate(" + (-this.c.height / 2) + ", " + (-this.c.margin.left) + ")")
      .attr("y", 6)
      .attr("dy", ".71em")
      .style("text-anchor", "middle");
    // .text("Temperature in Celcius");

    this.c.svgPath = this.c.svgTranformedg!.append("path")
      .attr("class", styles.line);

    //TOOLTIP
    this.c.focus = this.c.svgTranformedg.append("g")
      .attr("class", styles.focus)
      .style("display", "none");

    this.c.focus.append("circle")
      .attr("r", 5);

    this.c.svgOverlayRect = this.c.svgTranformedg.append("rect");
    this.c.svgOverlayRect
      .attr("class", styles.overlay)
      .attr("width", this.c.width)
      .attr("height", this.c.height)

    //Div tooltip
    this.c.divTooltip = d3.select("." + styles.divTooltip);
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: any, snapshot?: any) {
    // console.log("DIDUPDATE", this.props, prevProps, prevState);
    if (this.props.chartModel!.dataCounter > prevProps.chartModel!.dataCounter) {
      console.log("CAN UDPATE HERE");
      this.onNewData();
    }
  }

  render() {
    // console.log("RENDER", this.props)
    return (
      <Box className={styles.LineChart}
        data-testid="LineChart"
        // @ts-ignore: Ignore ref error, which is missing in typescript
        ref={this.ref}
      >
        <svg className={styles.svgElem} ref={this.svgRef}>
        </svg>
        <div className={styles.divTooltip} style={{ display: "none" }}>
          <div className={styles.divTooltipDate} >
          </div>
          <div>
            <span className={styles.divTooltipYtitle} >Temp: </span>
            <span className={styles.divTooltipYvalue} ></span>
          </div>
        </div>
      </Box>
    );
  }
};

export default LineChart;
