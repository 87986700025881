import {
  Box, CircularProgress, createStyles, IconButton, Paper, StyledComponentProps, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Theme, Typography, withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import cstyles from './SettingRegisters.module.scss';
import SensorDialog from '../SensorDialog/SensorDialog';
import { AddCircleOutlined, DeleteOutlined, EditOutlined } from '@material-ui/icons';
import { MbRegisterModel } from '../../../models/modbus/ModbusModels';
import { ModbusDataTypeJson, ModbusRegTypeJson } from '../../../models/modbus/enumerations';
import Axios from 'axios';
import { red } from '@material-ui/core/colors';
import produce from 'immer';

const styleWithTheme = (theme: Theme) => createStyles({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  buttonWrapperDiv: {
    position: "relative",
  },
  buttonProgress: {
    color: red[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
})

const generateEmptyRegisterModel = (): MbRegisterModel => ({
  name: '',
  plc_id: undefined,
  reg_type: '',
  reg_addr: undefined,
  data_type: '',
  data_endianness: "LITTLE",
  deleteBtnLoading: false
});

interface Props extends RouteComponentProps, StyledComponentProps {
  classes: Partial<ClassNameMap<string>>;
}

interface State {
  data: MbRegisterModel[];
  dialogIsOpen: boolean;
  isDialogModeNew: boolean;
  editDialogIndex?: number;
  editableModel: MbRegisterModel;
}

const SettingRegisters = withStyles(styleWithTheme, { withTheme: true })(
  class extends Component<Props, State> {

    constructor(props: Props) {
      super(props)
      this.state = {
        data: [],
        dialogIsOpen: false,
        isDialogModeNew: true,
        editableModel: generateEmptyRegisterModel()
      }
      this.onDialogClose = this.onDialogClose.bind(this)
      this.onDialogFormSubmit = this.onDialogFormSubmit.bind(this)
      this.onEditButtonClick = this.onEditButtonClick.bind(this)
      this.onDeleteButtonClick = this.onDeleteButtonClick.bind(this)
      this.onAddNewButtonClick = this.onAddNewButtonClick.bind(this)
    }

    onDialogClose() {
      console.log("on dialog close")
      this.setState({
        dialogIsOpen: false
      })
    }

    onDialogFormSubmit(model: MbRegisterModel, isNew: boolean, editModeIndex?: number) {
      // console.log("SUBMIT REQUIEST WITH", isNew, model, this.state.data)
      let copyData = [...this.state.data]
      if ( !isNew ) {
        copyData.splice(editModeIndex!, 1);
      }
      copyData.push(model);
      console.log("COOO", isNew, editModeIndex, copyData)

      let deviceId: string = "25E470";
      Axios.post("http://localhost:3050/device/" + deviceId + "/registers", copyData, {
        headers: {
          "content-type": "application/json"
        }
      }).then(value => {
        if (value.data.response === "FAILED") {
          console.log("FAILED RES: ", value.data)
          return;
        }
        let data = value.data.data;
        // console.log("REG DATA: ", data)
        this.setState({
          data: data,
          dialogIsOpen: false
        })
      }).catch(reason => {
        console.log("API ERROR", reason);
      })

      // window.setTimeout(() => {
      //   this.setState({
      //     dialogIsOpen: false
      //   })
      // }, 3000)

    }

    onAddNewButtonClick() {
      this.setState({
        dialogIsOpen: true,
        isDialogModeNew: true,
        editDialogIndex: undefined,
        editableModel: generateEmptyRegisterModel()
      })
    }

    onEditButtonClick(e: any, model: MbRegisterModel, index: number) {
      e.persist()
      console.log("ON EDIT BUTTON: ", model, e)

      this.setState({
        editableModel: model,
        dialogIsOpen: true,
        isDialogModeNew: false,
        editDialogIndex: index
      })
    }

    onDeleteButtonClick(e: any, model: MbRegisterModel, index: number) {
      e.persist()
      let newData = produce(this.state.data, (draft: MbRegisterModel[]) => {
        draft[index].deleteBtnLoading = true;
      })
      this.setState({
        data: newData
      })
      let tempArr = [...this.state.data]
      tempArr.splice(index, 1);
      console.log("onDeleteButtonClick: ", model, e, tempArr)

      let deviceId: string = "25E470";
      Axios.post("http://localhost:3050/device/" + deviceId + "/registers", tempArr, {
        headers: {
          "content-type": "application/json"
        }
      }).then(value => {
        if (value.data.response === "FAILED") {
          console.log("FAILED RES: ", value.data)
          return;
        }
        let data = value.data.data;
        // console.log("REG DATA: ", data)
        this.setState({
          data: data
        })
      }).catch(reason => {
        console.log("API ERROR", reason);
      })

      // window.setTimeout(() => {
      //   let newData = produce(this.state.data, (draft: MbRegisterModel[]) => {
      //     draft[index].deleteBtnLoading = false;
      //   })
      //   this.setState({
      //     data: newData
      //   })
      // }, 3000)
    }

    componentDidMount() {
      let deviceId: string = "25E470";
      Axios.get("http://localhost:3050/device/" + deviceId + "/registers", {
        headers: {
          "content-type": "application/json"
        }
      }).then(value => {
        if (value.data.response === "FAILED") {
          console.log("FAILED RES: ", value.data)
          return;
        }
        let data = value.data.data;
        // console.log("REG DATA: ", data)
        this.setState({
          data: data
        })
      }).catch(reason => {
        console.log("API ERROR", reason);
      })
    }

    render() {
      return (
        <Box className={cstyles.SettingRegisters} data-testid="SettingRegisters">
          <SensorDialog isOpen={this.state.dialogIsOpen} onClose={this.onDialogClose}
            onSubmit={this.onDialogFormSubmit} isModeNew={this.state.isDialogModeNew}
            editableModel={this.state.editableModel} editModeIndex={this.state.editDialogIndex}
          />
          <Box style={{ display: "flex", textAlign: "left", paddingLeft: "20px", paddingRight: "20px" }}>
            <Typography variant="h6" style={{ flexGrow: 1, marginTop: "auto", marginBottom: "auto" }}>Configure Registers</Typography>
            <IconButton style={{ marginTop: "auto", marginBottom: "auto" }}
              onClick={this.onAddNewButtonClick} >
              <AddCircleOutlined color={"primary"} fontSize={"large"}></AddCircleOutlined>
            </IconButton>
          </Box>
          <TableContainer component={Paper}>
            <Table className={this.props.classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Slave Address</TableCell>
                  <TableCell align="left">Register Type</TableCell>
                  <TableCell align="left">Register Address</TableCell>
                  <TableCell align="left">Data Type</TableCell>
                  <TableCell align="left">Endianness</TableCell>
                  <TableCell align="right"><Box style={{ paddingRight: "30px", minWidth: "70px" }}>Action</Box></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  this.state.data.map((val: MbRegisterModel, index: number) => {
                    return (
                      <TableRow key={val.reg_addr!}>
                        <TableCell component="th" scope="row">{val.name}</TableCell>
                        <TableCell align="left">{val.plc_id}</TableCell>
                        <TableCell align="left">{ModbusRegTypeJson[val.reg_type].name}</TableCell>
                        <TableCell align="left">{val.reg_addr}</TableCell>
                        <TableCell align="left">{ModbusDataTypeJson[val.data_type].name}</TableCell>
                        <TableCell align="left">{val.data_endianness === 'BIG' ? "BIG" : "LITTLE"}</TableCell>
                        <TableCell align="right" style={{ display: "flex" }}>
                          <div className={this.props.classes.buttonWrapperDiv}>
                            <IconButton onClick={(e) => this.onEditButtonClick(e, val, index)}>
                              <EditOutlined color="primary"></EditOutlined>
                            </IconButton>
                          </div>
                          <div className={this.props.classes.buttonWrapperDiv}>
                            <IconButton onClick={(e) => this.onDeleteButtonClick(e, val, index)}
                              disabled={val.deleteBtnLoading} >
                              <DeleteOutlined color="primary"></DeleteOutlined>
                            </IconButton>
                            {val.deleteBtnLoading &&
                              <CircularProgress size={24} className={this.props.classes.buttonProgress} />}
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box >
      );
    }
  }
);
export default SettingRegisters;
