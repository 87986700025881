export interface LineChartModel {
    title: string;
    xAxisLabel: string;
    yAxisLabel: string;
    valueLabel: string;
    data: DateValueDataType[];
    dataCounter: number;
    xTypeFormat: LineChartXDataType;
}

export interface DateValueDataType {
    timestamp: Date;
    value: number;
}

export enum LineChartXDataType {
    LINE_CHART_TYPE_MINUTE = 0,
    LINE_CHART_TYPE_HOURS = 1,
    LINE_CHART_TYPE_DAYS = 2
}